.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  .page-loader {
    &__object {
      width: 150px;
      height: 150px;
    }
  }
}
