.wrapper-default {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.modal-form-blk .in-blk .remain-hours-timer {
  background: rgb(164,11,28);
  background: linear-gradient(0deg, rgba(164,11,28,1) 0%, rgba(208,73,88,1) 43%, rgba(236,76,94,1) 100%);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 3px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 3px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 3px rgba(255, 255, 255, 0.3);
  display: block;
  margin: -32px 0 0 -32px;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  text-align: center;
  padding: 15px 0 0 0;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.modal-form-blk .in-blk .remain-hours-timer span {
  font-size: 28px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 600;
  display: block;
  font-family: "Bebas Neue", sans-serif;
}

@media screen and (max-width: 960px) {
  .modal-form-blk .in-blk .remain-hours-timer {
      position: relative;
      left: auto;
      top: auto;
      margin: 0 0 0 0;
      border-radius: 0px;
      width: 100%;
  }

}